import './Page.css'

function About(props) {
    return (
        <div class='blurb'>
            <p>my name is Viswanath Chadalapaka. i am a computer scientist. i 
                also play on <a href='https://lichess.org/@/Derposoft' target='_blank' rel='noreferrer'>lichess</a>.</p>
        </div>
    )
}

export default About